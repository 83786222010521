import React from "react";

const Logo = ({ width = 164, height = 51, className = "logo", style = {}, color = "#1F374B", showPeriod = true }) => {
  return (
    <svg version="1.1" fill={color} xmlns="http://www.w3.org/2000/svg" style={style} viewBox="0 0 501 92" className={className} width={width} height={height}>
      <g>
        <path d="M44,20.6v69.8H23.1V20.6H1.5V0.4h64.2v20.2H44z" />
        <path d="M70.3,90.4V0.4h19.3v29.9c3.8-3.1,9.1-4.9,14.7-4.9c18.1,0,27.9,12,27.9,29.3v35.7h-19.3V58.2 c0-8.6-3.3-15.1-11.8-15.1c-3.7,0-8.3,1.9-11.5,5.5v41.9H70.3z" />
        <path d="M136.5,58.4c0-18.8,13.3-33.1,33.1-33.1c16.3,0,30.9,10.6,30.9,30.9c0,1.8-0.1,3.7-0.4,5.6h-45 c1,8.6,8.2,11.9,14,11.9c6.8,0,11.1-3.3,13.7-8.1l15.4,10.4c-5.9,9.2-16.3,15.4-29,15.4C152.2,91.5,136.5,78.1,136.5,58.4z M181.2,50.1l0-0.3c-1.3-6.3-6.8-9-12.8-9c-4.5,0-11,2-13,9.3L181.2,50.1z" />
        <path d="M243.8,42.1h25.6v19.7h-25.6v28.6h-20.5V0.4h53.8v20.2h-33.3V42.1z" />
        <path d="M273.9,58.6c0-18.8,13.3-33.1,33-33.1c16.3,0,30.9,10.6,30.9,30.9c0,1.8-0.1,3.7-0.4,5.6h-45 c1,8.6,8.2,11.9,14,11.9c6.8,0,11.1-3.3,13.7-8.1l15.4,10.4c-5.9,9.2-16.3,15.4-29,15.4C289.6,91.6,273.9,78.3,273.9,58.6z M318.6,50.1l0-0.1c-1.3-6.3-6.8-9-12.8-9c-4.5,0-10.9,1.9-12.9,9.1H318.6z" />
        <path d="M341.6,58.6c0-18.8,13.3-33.1,33-33.1c16.3,0,30.9,10.6,30.9,30.9c0,1.8-0.1,3.7-0.4,5.6h-45 c1,8.6,8.2,11.9,14,11.9c6.8,0,11.1-3.3,13.7-8.1l15.4,10.4c-5.9,9.2-16.3,15.4-29,15.4C357.4,91.6,341.6,78.3,341.6,58.6z M386.4,50.1l0-0.1c-1.3-6.3-6.8-9-12.8-9c-4.5,0-10.9,1.9-12.9,9.1H386.4z" />
        <path d="M440.4,91.6c-19,0-31-15.5-31-33.1c0-17.5,12-33.1,31-33.1c5.3,0,10.4,1.8,14.1,4.9V0.4h19.3v90.3h-19.3v-3.8 C450.8,89.9,445.7,91.6,440.4,91.6z M454.5,68.6v-20c-2.6-3.5-6.2-5.4-10.9-5.4c-9.2,0-14.7,6.9-14.7,15.4 c0,8.5,5.5,15.4,14.7,15.4C448.3,74,451.9,72,454.5,68.6z" />
        {showPeriod && (
          <path d="M489.7,68.1c6.5,0,11.8,5.2,11.8,11.8c0,6.5-5.2,11.8-11.8,11.8c-6.5,0-11.8-5.2-11.8-11.8 C478,73.4,483.2,68.1,489.7,68.1z" />
        )}
      </g>
    </svg>
  )
}

export default Logo;